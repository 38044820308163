setViewerForComponent = function (id, content) {
    document.getElementById(id).innerHTML = content;
    com.wiris.js.JsPluginViewer.parseElement(
        document.getElementById(id),
        true,
        function () {}
    );
};
setRenderedData = function (id, content) {
    document.getElementById(id).innerHTML = content;
};
